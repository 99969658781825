/* common */
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  overflow-y: hidden;
}

body, p, h1, h2, h3, h4, h5, h6, ul, ol, dl, li, dt, dd {
  /* 默认有边距，都要清除 */
  margin: 0;
  padding: 0;
  /*字体设置*/
  font-size: 14px;
  font-family: Arial, serif;
  /* 去掉列表的原点 */
  list-style: none;
  /* 默认鼠标 */
  cursor: default;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}

.dn {
  display: none !important;
}

.db {
  display: block !important;
}

.dib {
  display: inline-block !important;
}

.vat {
  vertical-align: top;
}

.vam {
  vertical-align: middle;
}

#root {
  height: 100%;
}
