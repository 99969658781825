.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
  line-height: 32px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.mainContent {
  padding: 16px;
  min-height: 360px;
  background-color: #fff;
}